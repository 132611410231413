<template>
  <div class="page">
    <main class="content">
      <Header></Header>
      <router-view></router-view>
    </main>
<!--    <Footer></Footer>-->
  </div>
</template>

<script>
import Header from "@/components/HeaderComponent";
import Footer from "@/components/FooterComponent";

export default {
  components: { Footer, Header },
};
</script>

<style scoped>
html, body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}
</style>