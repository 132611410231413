import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import i18n from "./i18n";
import store from "./store"
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import axios from "axios";
import VueAwesomePaginate from "vue-awesome-paginate";
import VeeValidatePlugin from "@/plugins/VeeValidatePlugin";
import VueMask from '@devindex/vue-mask';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

axios.defaults.baseURL="https://admin.vivid.kz/api";
axios.defaults.params={
 lang: store.getters.getLang
};

// axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("access_token");

const app = createApp(App)
app.use(i18n)
app.use(store)
app.use(router)
app.use(VueMask);
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 10,
  timeout: 2000,
  newestOnTop: true,
})
app.use(clickOutSide)
app.use(VeeValidatePlugin)
app.use(VueAwesomePaginate)
app.config.productionTip = false
app.mount('#app')

