<template>
  <div class="footer">
    <div class="footer__container _container">
      <div class="footer__columns">
        <div
          class="footer__column"
          style="
            display: flex;
            flex-direction: column;
          "
        >
          <div class="footer__logo _ibg">
            <router-link to="/" class="footer__image">
              <img src="../assets/images/logo/logo11.png" alt="" />
            </router-link>
          </div>
          <div class="footer__group" style="align-items: center;">
            <span class="footer__icon">
              <img src="../assets/icons/footer/mail.svg" alt="" />
            </span>
            <a :href="`mailto:${contacts.socials[0].link}`" class="footer__link">{{
              contacts.socials[0].name
            }}</a>
          <div class="footer__social-mobile">
            <template  v-for="(item, idx) in contacts.socials" :key="idx">
              <a v-if="idx !== 0" target :href="item.link">
                <img :src="cdn + item.image" alt="" />
              </a>
            </template>
          </div>
          </div>
          <div class="footer__social">
            <template  v-for="(item, idx) in contacts.socials" :key="idx">
              <a v-if="idx !== 0" target :href="item.link">
                <img :src="cdn + item.image" alt="" />
              </a>
            </template>
          </div>
            <p class="nomad" v-html="$t('nomad')" />
        </div>
        <div class="footer__column">
          <h2 class="footer__title">{{ $t("labels.products") }}</h2>
          <ul class="footer__list">
            <li v-for="(category, id) in categories" :key="id">
              <router-link
                :to="{ name: 'Catalog', params: { slug: category.slug } }"
                class="footer__link"
                @click="setCategory(category)"
              >
                {{ category.title }}
              </router-link>
            </li>
          </ul>
        </div>

        <div class="footer__column">
          <h2 class="footer__title">{{ $t("labels.info") }}</h2>
          <ul class="footer__list">
            <li v-for="(link, i) in footer" :key="i">
              <template
                v-if="
                  link.slug === 'wholesale-department' || link.slug === 'news'
                "
                ><router-link
                  @click="menuIsOpen = false"
                  :to="'/' + link.slug"
                  class="footer__link"
                >
                  {{ $t(`nav.${link.slug}`) }}
                </router-link>
              </template>
              <template v-else
                ><router-link
                  @click="menuIsOpen = false"
                  :to="{ name: 'About', params: { slug: link.slug } }"
                  class="footer__link"
                >
                  {{ $t(`nav.${link.slug}`) }}
                </router-link>
              </template>
            </li>
            <!--              <li>-->
            <!--                <router-link to="/news" class="footer__link">-->
            <!--                  Новости-->
            <!--                </router-link>-->
            <!--              </li>-->
            <li>
              <router-link to="/contacts" class="footer__link">
                {{ $t("contacts") }}
              </router-link>
            </li>
            <li>
              <router-link to="/certificates" class="footer__link">
                {{ $t("certific") }}
              </router-link>
            </li>
          </ul>
        </div>

        <div class="footer__column">
          <h2 class="footer__title">
            {{ $t("contacts") }}
          </h2>
          <div class="footer__items">
            <div class="footer__item">
              <div class="footer__text">{{ $t("labels.retail") }}</div>
              <div class="footer__group" v-for="(item, idx) in contacts.data[0].phone_numbers" :key="idx">
                <span class="footer__icon">
                  <img
                    :src="require('../assets/icons/footer/phone-call.svg')"
                    alt=""
                  />
                </span>
                <a :href="'tel:'+item.phone" class="footer__link">{{
                  item.phone + ' ' + item.city
                }}</a>
              </div>

              <div class="footer__group">
                <span class="footer__icon">
                  <img src="../assets/icons/footer/map.svg" alt="" />
                </span>
                <div class="footer__link">{{ contacts.data[0].main_address }}</div>
              </div>
            </div>
            
            <div class="footer__item">
              <div class="footer__text">{{ $t("labels.wholesale") }}</div>
              <div class="footer__group" v-for="(item, idx) in contacts.data[1].phone_numbers" :key="idx">
                <span class="footer__icon">
                  <img
                    :src="require('../assets/icons/footer/phone-call.svg')"
                    alt=""
                  />
                </span>
                <a :href="'tel:'+item.phone" class="footer__link">{{
                  item.phone + ' ' + item.city
                }}</a>
              </div>

              <div class="footer__group">
                <span class="footer__icon">
                  <img src="../assets/icons/footer/map.svg" alt="" />
                </span>
                <div class="footer__link">{{ contacts.data[1].main_address }}</div>
              </div>
            </div>

            <!-- <div class="footer__item">
              <div class="footer__group">
                <span class="footer__icon">
                  <img src="../assets/icons/footer/phone-call.svg" alt="" />
                </span>
                <a href="tel:+7(777)2542574" class="footer__link">{{
                  formatPhoneNumber(contacts.wholesale)
                }}</a>
              </div>
              <div class="footer__text">{{ $t("labels.wholesale") }}</div>
            </div> -->
          </div>
        </div>
      </div> 
      <hr />
      <div class="footer__copyright footer__copyright_second" v-if="getFiles">
        <div>
          © {{ $t("vivid") }}
        </div>
        <div class="footer__copyright-right">
          <a class="footer__copyright-right-item" :href="'https://admin.vivid.kz' + getFiles.privacyPoliceLink" download>
            <img
              src="../assets/icons/download.png"
              alt="download"
            /> {{ $t("users") }}
          </a>
          
          <a class="footer__copyright-right-item" :href="'https://admin.vivid.kz' + getFiles.userAgreementLink" download>
            <img
              src="../assets/icons/download.png"
              alt="download"
            /> {{ $t("privacy") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "Footer",
  data() {
    return {
      footer: [
        { name: "О компании", slug: "about" },
        { name: "Партнеры", slug: "cooperation" },
        { name: "Новости", slug: "news" },
        // { name: "Оптовый отдел", slug: "wholesale-department" },
        { name: "Установка", slug: "loyalty" },
        { name: "Доставка", slug: "delivery" },
      ],
      news: null,
    };
  },
  computed: {
    ...mapState(["contacts", "categories", "cdn"]),
    ...mapGetters(["getFiles"])
  },
  async mounted() {
    this.fetchFiles()
  },
  methods: {
    ...mapMutations(["SET_CATEGORY"]),
    ...mapActions(["fetchFiles"]),
    setCategory(category) {
      this.SET_CATEGORY(category.id);
    },
    formatPhoneNumber(tel) {
      let cleaned = ("" + tel).replace(/\D/g, "");
      let format = cleaned.replace(/^8/, 7);
      let regex = /^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})/;
      if (format.match(regex)) {
        return format.replace(regex, "+$1 ($2) $3 $4-$5");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nomad {
  margin-top: 20px;
  @media (max-width: $mobile + px) {
    font-size: 14px;
    margin-top: 20px;
  }
}
hr {
  height: 1px;
  position: absolute;
  left: 0;
  width: 99vw;
  background: #C2C2C2;
  margin-bottom: 18px;
}
.footer {
  background: #0d0d0d;
  color: #ffffff;
  padding: 37px 0 30px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 30px 0 87px 0;
  }

  &__logo {
    width: 105px;
    margin: 0 0 49px 0;
    height: 56px;
    @media (max-width: $tablet + px) {
      width: 61px;
      height: 35px;
      margin: 0 0 20px 0;
    }
  }

  &__copyright {
    margin-top: 48px;
    display: flex;
    color: #828282;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 118.75%;
    &-right {
      display: flex;
      align-items: center;
      gap: 20px;
      &-item {
        gap: 6px;
        display: flex;
        align-items: center;
      }
      @media (max-width: $tablet + px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }
    }
    @media (max-width: $tablet + px) {
      font-size: 14px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 12px;
    }
    &_first {
      @media (max-width: $mobile + px) {
        display: none;
      }
    }
    &_second {
    }
  }

  &__title {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 118.75%;
    text-transform: uppercase;
    padding: 0 0 5px 0;
    border-bottom: 1px solid #727272;
    margin: 0 0 31px 0;
    @media (max-width: $tablet + px) {
      font-size: 15px;
      margin: 0 0 18px 0;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
      margin: 0 0 15px 0;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 11px;
    img {
      width: 30px;
      height: 30px;
    }
    &-mobile {
      display: none;
    }
    
    @media (max-width: $tablet + px) {
      display: none;
      &-mobile {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        flex-grow: 1;
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  &__columns {
    margin-bottom: 28px;
    @media (min-width: $mobile + px) {
      display: flex;
      justify-content: space-between;
      gap: 0 50px;
    }
    @media (max-width: $tablet + px) {
      gap: 0 30px;
    }
  }

  &__column {
    &:nth-child(3) {
      .footer__list {
        @media (max-width: $mobileSmall + px) {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          gap: 0 28px;
          li {
            flex: 0 1 138px;
          }
        }
      }
    }

    &:not(:last-child) {
      margin: 0 0 26px 0;
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 22px 0;
      }
    }

    @media (max-width: $tablet + px) {
      flex: 0 1 50%;
    }
  }

  &__list {
    li {
      &:not(:last-child) {
        margin: 0 0 20px 0;
        @media (max-width: $tablet + px) {
          margin: 0 0 17.5px 0;
        }
        @media (max-width: $mobileSmall + px) {
          margin: 0 0 15px 0;
        }
      }
    }
  }

  &__item {
    max-width: 370px;
    @media (max-width: $mobileSmall + px) {
      max-width: 292px;
    }
    &:not(:last-child) {
      margin: 0 0 17px 0;
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 12px 0;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    margin: 0 0 8.5px 0;

    &_top {
      align-items: flex-start;
    }

    @media (max-width: $mobileSmall + px) {
      margin: 0 0 5px 0;
    }
  }

  &__link {
    font-weight: 400;
    font-size: 16px;
    line-height: 137.5%;
    transition: all 0.3s ease;
    @media (max-width: $tablet + px) {
      font-size: 15px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px 0 0;

    img {
      width: 19.97px;
      height: 19.97px;
    }

    @media (max-width: $tablet + px) {
      width: 18px;
      height: 18px;
      img {
        width: 17.97px;
        height: 17.97px;
      }
    }

    @media (max-width: $mobileSmall + px) {
      width: 16px;
      height: 16px;
      img {
        width: 15.57px;
        height: 15.57px;
      }
    }
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 118.75%;
    color: #727272;
    margin-bottom: 8.5px;
    @media (max-width: $tablet + px) {
      font-size: 15px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }
}
ul li {
  list-style: none;
}
</style>