import {createStore} from 'vuex'
import axios from "axios";
import router from "@/router";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
        }),
    ],
    state() {
        return {
            cart: JSON.parse(localStorage.getItem('cart')) || [],
            user: {},
            auth: null,
            cities: [],
            regions: [],
            favorites: [],
            brands: [],
            news: [],
            files: {},
            lang: localStorage.getItem('lang') || "ru",
            newsId: null,
            categories: [],
            popularProducts: [],
            specialOffers: [],
            fundsSale: [],
            slider: {},
            contacts: {},
            sub: null,
            category: null,
            product: 0,
            orders: [],
            shops: [],
            citiesContacts: [],
            homeCategories: [],
            cdn: 'https://cdn.vivid.kz/',
        }
    },
    getters: {
        popularProducts(state) {
            return state.popularProducts
        },
        productQuantity: state => product => {
            const item = state.cart.find(i => i.id === product.id)
            if (item) return item.quantity
            else return null
        },
        cartTotal: state => {
            return state.cart.reduce((a, b) => b.typeBuy === 1 ? a + (b.current_price * b.quantity) : a + (b.current_price * b.quantity * b.features.quantity), 0)
        },
        cartCount: state => {
            return state.cart.reduce((count, product) => count + product.quantity, 0);
        },
        favoriteCount: state => {
            return state.auth ? state.favorites.length : state.favorites.reduce((a, b) => a + b.quantity, 0)
        },
        individualUser: state => {
            if (state.user.type === 'individual') return true
        },
        getAuth(state) {
            return state.auth
        },
        getCart(state) {
            return state.cart
        },
        getLang: (state) => state.lang,
        getOrders: (state) => state.orders,
        getFiles: (state) => state.files
    },

    mutations: {
        UPDATE_LANG(state, lang) {
            state.lang = lang
            localStorage.setItem("lang", lang);
        },
        SET_HOME_CATEGORIES(state, categories) {
            state.homeCategories = categories
        },
        SET_CART(state, cart) {
            state.cart = cart
        },
        SET_SHOPS(state, shops) {
            state.shops = shops
        },
        SET_ORDERS(state, orders) {
            state.orders = orders
        },
        SET_CITIES_CONTACTS(state, contacts) {
            state.citiesContacts = contacts
        },
        SET_FAVORITES(state, favorites) {
            state.favorites = favorites
        },
        SET_PRODUCT(state, product) {
            state.product = product
        },
        SET_CATEGORY(state, category) {
            state.category = category
        },
        SET_CATEGORY1(state, category) {
            state.sub = category
        },
        SET_AUTH(state, auth) {
            state.auth = auth
        },
        SET_NEWS(state, news) {
            state.news = news
        },
        SET_NEWS_ID(state, news_id) {
            state.newsId = news_id
        },
        SET_SLIDER(state, slider) {
            state.slider = slider
        },
        SET_CONTACTS(state, contacts) {
            state.contacts = contacts
        },
        SET_CATEGORIES(state, categories) {
            state.categories = categories
        },
        SET_OFFERS(state, offers) {
            state.specialOffers = offers
        },
        SET_FUNDS(state, fundsSale) {
            state.fundsSale = fundsSale
        },
        SET_POPULAR(state, popular) {
            state.popularProducts = popular
        },
        SET_FILES(state, files) {
            state.files = files
        },
        SET_BRANDS(state, brands) {
            state.brands = brands
        },
        SET_USER(state, user) {
            state.user = user
        },
        SET_CITIES(state, cities) {
            state.cities = cities
        },
        SET_REGIONS(state, regions) {
            state.regions = regions
        },
        addToCart(state, product) {
            let item = state.cart.find(i => i.id === product.id)
            if (item) {
                product.quantity === 1 ? item.quantity++
                    : product.quantity === 0 ? item.quantity = 1
                        : item.quantity = parseInt(product.quantity)
                product.typeBuy ? item.typeBuy = product.typeBuy : item.typeBuy = 0
            } else state.cart.push({...product, quantity: product.quantity ? product.quantity : 1, typeBuy: product.typeBuy})
            updateCartLocalStorage(state.cart)
        },
        addToCart1(state, product) {
            let item = state.cart.find(i => i.id === product.id)
            if (item) {
                product.funds1 ? item.funds1=product.funds1 : null
                product.funds2 ? item.funds2=product.funds2 : null
            }
            updateCartLocalStorage(state.cart)
        },

        deleteProduct(state, product) {
            state.cart = state.cart.filter(i => i.id !== product.id)
        },

        removeCart(state, product) {
            let item = this.state.cart.find(i => i.id === product.id)
            if (item) {
                if (item.quantity > 1) {
                    item.quantity--
                } else {
                    state.cart = state.cart.filter(i => i.id !== product.id)
                }
            }
            updateCartLocalStorage(state.cart)
        },

        addFavoriteToLocal(state, product) {
            let item = state.favorites.find(i => i.id === product.id)
            if (item) {
                state.favorites = state.favorites.filter(i => i.id !== product.id)
            } else state.favorites.push({...product, quantity: 1})

            updateFavoritesLocalStorage(state.favorites)
        },
    },

    actions: {
        checkAuth({commit}) {
            if (localStorage.getItem("access_token")) commit("SET_AUTH", true)
            else commit("SET_AUTH", false);
        },
        async fetchRegions({commit}) {
            await axios.get(`V1/regions`)
                .then(response => {
                    commit("SET_REGIONS", response.data.data);
                })

                .catch(error => {
                     
                })

        },
        async fetchHomePage({commit}) {
            await axios.get(`V1/home-page`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
                .then(response => {
                    commit("SET_BRANDS", response.data.data.brands);
                    commit("SET_SLIDER", response.data.data.slider);
                    commit("SET_NEWS", response.data.data.news);
                    commit("SET_HOME_CATEGORIES", response.data.data.categories)
                    commit("SET_POPULAR", response.data.data.products.best);
                    commit("SET_OFFERS", response.data.data.products.sale);
                    commit("SET_FUNDS", response.data.data.products.funds);
                })

                .catch(error => {
                     
                })
        },
        async fetchCities({commit}, region) {
            await axios.get(`V1/cities`, {
                params: {
                    region_id: region
                },
            })
                .then(response => {
                    commit("SET_CITIES", response.data.data);
                })

                .catch(error => {
                     
                })
        },
        async fetchContacts({commit}) {
            await axios.get(`get-contacts`)
                .then(response => {
                    commit("SET_CONTACTS", response.data);
                    commit("SET_CITIES_CONTACTS", response.data.cities)
                })

                .catch(error => {
                     
                })
        },
        async fetchFavorites({commit}) {
            await axios.get(`V1/get-favourites`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
                .then(response => {
                    commit("SET_FAVORITES", response.data.data);
                })

                .catch(error => {
                     
                })
        },
        async addFavorite({commit}, product_id) {
            await axios.post('V1/add-favourite', product_id, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
                .then(response => {
                })
                .catch(error => {
                     
                })
        },
        async removeFavorite({commit}, favorite_id) {
            await axios.delete('V1/delete-favourite', {
                params: {
                    user_favourite_id: favorite_id
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
                .then(response => {
                })
                .catch(error => {
                     
                })
        },
        async fetchCategories({commit}) {
            await axios.get(`V1/categories`)
                .then(response => {
                    commit("SET_CATEGORIES", response.data.data);
                })

                .catch(error => {
                     
                })
        },
        async fetchOrders({commit}) {
            await axios.get(`V1/orders`, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
              })
                .then(response => {
                    commit("SET_ORDERS", response.data.data);
                })

                .catch(error => {
                     
                })
        },
        async fetchFiles({commit}) {
            await axios.get(`V1/files`)
                .then(response => {
                    commit("SET_FILES", response.data.data);
                })

                .catch(error => {
                     
                })
        },
        async leaveRequest({commit}, form) {
            await axios
                .post('feedback', form)
                .then(response => {
                })
                .catch(errors => {
                });
        },
        async requestUser({commit, dispatch}) {
            await axios.get(`V1/me`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
                .then(response => {
                    commit("SET_USER", response.data.data);
                })

                .catch(error => {
                     
                    localStorage.removeItem("access_token");
                    // dispatch.checkAuth()
                })
        },
    },

    created() {
        // Retrieve the cart data from localStorage and parse it into an array
        const cartData = JSON.parse(localStorage.getItem('cart')) || [];

        // Set the cart data in the Vuex state
        this.$store.commit('setCart', cartData);
    },

    // modules: {
    //     cart,
    //     login
    // }

})

function updateCartLocalStorage(cart) {
    localStorage.setItem('cart', JSON.stringify(cart))
}

function updateFavoritesLocalStorage(cart) {
    localStorage.setItem('favorites', JSON.stringify(cart))
}

export default store;


