import {Form, Field, ErrorMessage, defineRule, configure} from "vee-validate";
import {required, email, min, max, numeric, confirmed, is} from "@vee-validate/rules";
import { localize } from "@vee-validate/i18n";
import en from "../locales/en.json";
import kz from "../locales/kz.json";
import ru from "../locales/ru.json";

export default (app) => {
    var land = localStorage.getItem('lang') || "ru"
    var object = ru
    if (land === "en") {
        object = en
    } else if (land === "kz") {
        object = kz
    } else {
        object = ru
    }
    defineRule('selected', (value) => {
        if(value.id) return true
        else {
            return 'Укажите адрес доставки'
        }
    })
    defineRule('required', required)
    defineRule('email', email)
    defineRule('min', min)
    defineRule('numeric', numeric)
    defineRule('confirmed', confirmed)
    defineRule('is', is)
    configure({
        validateOnBlur: false,
        validateOnChange: true,
        validateOnInput: false,
        validateOnModelUpdate: false,
        generateMessage: localize({'en': {
            messages: {
                required: object.errors.required,
                email: object.errors.email,
                min: object.errors.min,
                numeric: object.errors.numeric,
                is: object.errors.is
            }
        }})
    })
    app.component('VeeForm', Form)
    app.component('VeeField', Field)
    app.component('VeeErrorMessage', ErrorMessage)
}
