import { createRouter  , createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/HomePage')
    },

    {
        path: '/product/:slug',
        name: 'ProductCard',
        component: () => import('../views/ProductCard'),
    },

    {
        path: '/catalog',
        name: 'CatalogName',
        component: () => import('../views/ProductCatalog'),
    },

    {
        path: '/results',
        name: 'Results',
        component: () => import('../views/ResultSearch'),
    },

    {
        path: '/catalog/:slug',
        name: 'Catalog',
        component: () => import('../views/ProductCatalog'),
    },

    {
        path: '/shopping-cart',
        name: 'ShoppingCart',
        component: () => import('../views/ShoppingCart'),
    },
    {
        path: '/favorites',
        name: 'Favorites',
        component: () => import('../views/FavoritesPage'),
    },
    {
        path: '/personal-account',
        name: 'PersonalAccount',
        component: () => import('../views/PersonalAccount'),
        meta: {
          requiresAuth: true,
        },
    },
    {
        path: '/certificates',
        name: 'Certificates',
        component: () => import('../views/CompanyCertificates'),
    },
    {
        path: '/wholesale-department',
        name: 'WholesaleDepartment',
        component: () => import('../views/WholesaleDepartment'),
    },
    {
        path: '/partners',
        name: 'CompanyPartners',
        component: () => import('../views/CompanyPartners'),
    },
    {
        path: '/news',
        name: 'NewsMain',
        component: () => import('../views/News'),
    },
    {
        path: '/news/:id',
        name: 'News',
        component: () => import('../views/CompanyNews'),
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: () => import('../views/CompanyContacts'),
    },
    {
        path: '/about/:slug',
        name: 'About',
        component: () => import('../views/AboutPage'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/LoginPage'),
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('../views/ResetPassword'),
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        component: () => import('../views/ChangePassword'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (localStorage.getItem("access_token")) {
        next();
      } else {
        next({ name: "Login" });
      }
    } else {
      next();
    }
  });

export default router